import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useModal } from '../../../hooks';
import { useParams } from 'react-router';
import { useAsset, useChangeFavourites } from '../api';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import AssetDownloadFileButton from './AssetDownloadFileButton';
import CopyButtons from 'components/common/CopyButtons';
import { AssetChip, AssetChipLabel } from './AssetsListComponents';

const MediaDescription = () => {
  const queryClient = useQueryClient();
  const { toggle: toggleAiPrompt, isOpen: isAIPromptOpen } = useModal();
  const { assetId } = useParams();
  const { mutateAsync: changeFavourite, isPending: isFavouritesPending } = useChangeFavourites({
    config: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['assets', assetId] });
      },
    },
  });

  const { data: asset } = useAsset({ id: Number(assetId) });

  const assetIcon = useMemo(() => {
    if (isFavouritesPending) {
      return <CircularProgress size={20} sx={{ color: 'black' }} />;
    }

    return asset.favourite ? <StarRateIcon /> : <StarBorderIcon />;
  }, [asset.favourite, isFavouritesPending]);

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" mb={0.5}>
          {asset.name}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2.5 }}>
          <Button
            onClick={() => changeFavourite({ asset_id: Number(assetId), favourite: !asset.favourite })}
            sx={{ textTransform: 'uppercase' }}
            startIcon={assetIcon}
            variant="text"
          >
            Favourite
          </Button>
          <CopyButtons value={asset.name} />
        </Box>
      </Box>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, pb: 4, mb: 4, borderBottom: '1px solid rgba(0,0,0,0.12)' }}
      >
        {asset.tags.map(({ id, name }) => (
          <AssetChip
            sx={(t) => ({
              borderRadius: t.borderRadius.xs,
              margin: '0 !important',
              pl: 0.5,
              pr: 0.5,
            })}
            size="small"
            key={id}
            label={<AssetChipLabel label={name} />}
          />
        ))}
      </Box>
      <Box sx={{ pb: 4, mb: 4, borderBottom: '1px solid rgba(0,0,0,0.12)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          <Box sx={{ mr: 2 }}>
            <AssetDownloadFileButton assetId={asset.id} variant="icon-text" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" mb={1}>
          Comment
        </Typography>
        <Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="text.secondary">
          {asset.comment}
        </Typography>
      </Box>
      {asset.ai_prompt && (
        <Box sx={{ mb: 4 }}>
          <Accordion
            disableGutters
            sx={{
              boxShadow: 'unset',
              '&::before': { display: 'none' },
            }}
            expanded={isAIPromptOpen}
            onChange={toggleAiPrompt}
          >
            <AccordionSummary
              sx={{
                px: 0,
                justifyContent: 'flex-start',
                '.MuiAccordionSummary-content': { flexGrow: 0 },
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ai-prompt"
              id="ai-prompt"
            >
              <Typography sx={{ flexShrink: 0, mr: 1 }}>AI prompt</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {asset.ai_prompt}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <List>
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Created for
          </Typography>
          <Typography variant="body1">{asset.projects.map((p) => p.name).join(', ')}</Typography>
        </ListItem>
        <Divider />
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Access
          </Typography>
          <Typography variant="body1">{!asset.restricted ? 'Public' : 'Private'}</Typography>
        </ListItem>
        <Divider />
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Customer
          </Typography>
          <Typography variant="body1">{asset.customer}</Typography>
        </ListItem>
        <Divider />
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Performer
          </Typography>
          <Typography variant="body1">{asset.performer}</Typography>
        </ListItem>
        <Divider />
      </List>
    </>
  );
};

export default MediaDescription;

import {
  Link as LinkIcon,
  ContentCopy as ContentCopyIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useAnchorMenu } from 'hooks';
import { useCallback } from 'react';
import type { FC } from 'react';
import type { AssetEntry } from '../api';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useAssets } from '..';
import { useNavigate } from 'react-router';

interface AssetItemContextMenuProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  asset: AssetEntry;
}

const AssetItemContextMenu: FC<AssetItemContextMenuProps> = ({ parentRef, asset: { id, name, media_url } }) => {
  const { onShowAlert } = useEnqueueSnackbar();
  const { isList } = useAssets();
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();
  const navigate = useNavigate();

  const handleCopyValue = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      onShowAlert('Copied');
      onCloseMenu();
    } catch (err) {
      onShowAlert('Error occurs');
    }
  };

  const editAssetRedirect = () => {
    navigate(`update/${id}`);
  };

  const duplicateAssetRedirect = () => {
    navigate(`create?baseAsset=${id}`);
  };

  const onOpenMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      parentRef.current?.classList.add('action-hover');
      handleOpenMenu(e);
    },
    [handleOpenMenu]
  );

  const onCloseMenu = useCallback(() => {
    parentRef.current?.classList.remove('action-hover');
    handleCloseMenu();
  }, []);

  return (
    <>
      <Button
        aria-controls={isOpen ? `asset-${id}-context-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={onOpenMenu}
        variant="contained"
        color="info"
        disableElevation
        sx={(t) => ({
          minWidth: isList ? 30 : 36,
          maxWidth: isList ? 30 : 36,
          minHeight: isList ? 30 : 36,
          p: 0,
          opacity: 0.9,
          borderRadius: t.borderRadius.sm,
          [`&:hover`]: {
            opacity: 0.75,
          },
        })}
      >
        <MoreVertIcon sx={{ color: 'black', fontSize: 22 }} />
      </Button>

      <Menu id={`asset-${id}-context-menu`} anchorEl={anchor} open={isOpen} onClose={onCloseMenu}>
        <MenuItem onClick={() => handleCopyValue(media_url)}>
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ pl: 1 }}>Copy link</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleCopyValue(name)}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ pl: 1 }}>Copy name</ListItemText>
        </MenuItem>

        <MenuItem onClick={duplicateAssetRedirect}>
          <ListItemIcon>
            <FileCopyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ pl: 1 }}>Duplicate</ListItemText>
        </MenuItem>

        <MenuItem onClick={editAssetRedirect}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ pl: 1 }}>Edit</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AssetItemContextMenu;

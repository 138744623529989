import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { Cancel, Search } from "@mui/icons-material";
import { Box, Button, Chip } from "@mui/material";
import { useSearchTagsState } from "features/assets";
import { useModal } from "hooks";
import { sortTagGroups, useSyncTagGroups } from "features/search";
import useListParams from "hooks/useListParams";
import { useSearchContext } from "../context";

interface SearchValuesButtonProps {
  onClick: () => void;
  title: string;
};

const SearchValuesButton: FC<SearchValuesButtonProps> = ({ onClick, title }) => {
  return (
    <Button
      variant="text"
      sx={t => ({
        borderRadius: t.borderRadius.sm,
        backgroundColor: 'transparent',
        marginRight: 1,
        height: 24,
      })}
      onClick={onClick}>{title}</Button>
  )
};

const SearchValues: FC = () => {
  const { onResetValues, searchType } = useSearchContext();

  const { toggle: onToggleShowMore, isOpen: isShowMore } = useModal();
  const { tags, setTags, q } = useListParams();
  const { selectedTags, onSelectTag, onResetStateTags } = useSearchTagsState();

  const { data: searchTags } = useSyncTagGroups(searchType, {
    config: {
      enabled: true,
      select: ({ data }) => {
        const queryTagsValue = tags?.length ? (tags as number[]) : [];
        const sortedData = sortTagGroups(data, queryTagsValue);

        return [
          [...sortedData].slice(0, 20),
          [...sortedData].slice(20, sortedData.length),
        ]
      },
    },
  });

  const handleDeleteTag = useCallback((tagId: number) => {
    onSelectTag(tagId, false);

    const selectedTagsState = selectedTags.filter((selectedTag) => selectedTag !== tagId);
    setTags(selectedTagsState);
  }, [selectedTags, setTags, onSelectTag]);

  const handleResetAll = useCallback(() => {
    onResetStateTags();
    onResetValues();
  }, [onResetValues, onResetStateTags])

  const memoSearchValues = useMemo(() => ((q?.length) ?? searchTags?.length) ? (
    <>
      {(tags?.length ?? q?.length) && <SearchValuesButton onClick={handleResetAll} title="Reset all" />}
      {q?.length ? <Chip
        label={q}
        icon={<Search sx={t => ({ width: 18, color: t.palette.primary.main, ml: 1, })} />}
        deleteIcon={<Cancel />}
        onDelete={() => { onResetValues({ param: 'q', }); }}
        sx={t => ({
          mb: 1,
          '& .MuiChip-label': {
            color: t.palette.primary.main
          },
          '.MuiChip-deleteIcon': {
            color: t.palette.grey[600]
          },
          '.MuiChip-deleteIcon:hover': {
            color: t.palette.grey[800]
          },
          '&.MuiChip-root': {
            backgroundColor: t.palette.grey[100]
          },
        })} /> : null}
      {searchTags?.[0].map(tag => (
        <Chip
          color="primary"
          key={`selectedTag.${tag.tag_id}:${tag.tag_name}`}
          label={tag.tag_name}
          deleteIcon={<Cancel />}
          onDelete={() => { handleDeleteTag(tag.tag_id); }} />
      ))}
      {
        ((tags && tags.length > 20) && !isShowMore)
          ? <SearchValuesButton onClick={onToggleShowMore} title="Show all" /> : null
      }
      {
        (isShowMore && searchTags) ? searchTags[1].map(tag => (
          <Chip
            color="primary"
            key={`selectedTag.${tag.tag_id}:${tag.tag_name}`}
            label={tag.tag_name}
            deleteIcon={<Cancel />}
            onDelete={() => { handleDeleteTag(tag.tag_id); }} />
        )) : null
      }
      {isShowMore && <SearchValuesButton onClick={onToggleShowMore} title="Show less" />}
    </>
  ) : null, [q, isShowMore, onToggleShowMore, handleDeleteTag, handleResetAll, tags, searchTags, onResetValues]);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 1, }}>
      {memoSearchValues}
    </Box>
  );
}

export default SearchValues;

import { Box, Typography } from "@mui/material";
import type { TagEntry, TagGroupEntry } from "features/assets";
import { useSearchTagsState, useTagGroups } from "features/search";
import { type FC, useMemo, useCallback, useState, useEffect } from "react";
import { SearchNoResults } from "./SearchComponents";
import Checkbox from "components/common/Checkbox";
import { useSearchContext } from "../context";
import { useFormContext } from "react-hook-form";

const TagItem: FC<{ tag: TagGroupEntry }> = ({ tag }) => {
  const { setQueryFieldValue } = useSearchContext();
  const { watch, setValue } = useFormContext()
  const stateSearchTags = watch('searchTags') as Record<string, boolean>;

  const handleClearField = useCallback(() => {
    setQueryFieldValue('');
  }, [setQueryFieldValue]);

  const handleChecked = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleClearField();
    setValue(e.target.name, checked);
  }, [setValue, handleClearField]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
      <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 36, backgroundColor: 'white', zIndex: 3, position: 'sticky', top: '-16px', left: 0, px: 1, mx: -1, }}>
        <Typography
          sx={t => ({
            fontSize: t.typography.body2.fontSize,
            lineHeight: t.typography.body2.lineHeight,
            fontWeight: 500,
          })}>{tag.group_name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', }}>
        {tag.tags.length ? tag.tags.map((t: TagEntry) => (
          <Checkbox onChange={handleChecked} key={`${t.tag_id}:${t.tag_name}:${stateSearchTags[t.tag_id]}`} name={'searchTags.' + t.tag_id} label={t.tag_name} inputProps={{ name: 'searchTags.' + t.tag_id }} />
        )) : <Typography sx={t => ({ fontSize: 13, color: t.palette.grey[600], })}>Tag group is empty</Typography>}
      </Box>
    </Box>
  )
};

const SearchResultTags: FC = () => {
  const { searchType, queryFieldValue } = useSearchContext();
  const { selectedTags } = useSearchTagsState();
  const isQueryFieldValue = queryFieldValue.length >= 2 || !!selectedTags.length;
  const [lastFieldValue, setLastFieldValue] = useState(queryFieldValue);

  const { data: { data } } = useTagGroups(
    searchType,
    {
      config: {
        enabled: isQueryFieldValue,
      },
      params: { q: lastFieldValue },
    });

  useEffect(() => {
    if (isQueryFieldValue) {
      setLastFieldValue(queryFieldValue);
    }
  }, [isQueryFieldValue, queryFieldValue]);

  const sortedData = useMemo(() => data.sort((a, b) => a.tags.length - b.tags.length), [data]);

  return (
    <Box sx={t => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      py: 2,
      px: 1,
      overflow: 'hidden',
      overflowY: 'scroll',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: sortedData.length ? t.palette.grey[300] : 'transparent',
    })}>
      {sortedData.length ? <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 30, mb: 1, }}>
        <Typography
          sx={t => ({
            fontSize: t.typography.body1.fontSize,
            lineHeight: t.typography.body1.lineHeight,
            color: t.palette.grey[600],
            fontWeight: 400,
          })}>Similar tags</Typography>
      </Box> : null}
      <Box sx={{
        display: 'grid',
        gridColumnGap: '16px',
        gridRowGap: '0',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
        maxHeight: 300,
      }}>
        {sortedData.length
          ? sortedData.map((tag: TagGroupEntry) => <TagItem key={`tagGroupResult.${tag.group_id}:${tag.group_name}`} tag={tag} />)
          : <SearchNoResults text="No similar tags" />}
      </Box>
    </Box>
  )
}

export default SearchResultTags;

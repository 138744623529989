import * as yup from 'yup';

export const assetSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  mediaUrl: yup.string().required().url().label('Media URL'),
  thumbnail: yup.string().required().url().label('Thumbnail'),
  tags: yup.object().shape({}).label('Tags'),
  comment: yup.string().label('Comment'),
  aiPrompt: yup.string().label('AI prompt'),
  restricted: yup.boolean().required().label('Access level'),
  performer: yup.string().label('Performer'),
  customer: yup.string().label('Customer'),
  projects: yup.array().of(yup.number().required()).required().label('Projects'),
});

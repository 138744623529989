import * as yup from 'yup';

const creativeEditSchema = yup.object().shape({
  id: yup.number(),

  access: yup.boolean().required(),

  media_type: yup.string(),
  model_name: yup.string(),
  comment: yup.string().required(),
  thumbnail: yup.string().required(),
  download_files_link: yup.string().required(),
  model_video_id: yup.string().required(),
  original_text: yup.string().required(),
  original_creative_asset: yup.string().required(),
  base_id: yup.string().required("Field can’t be empty"),
  file_name: yup.string().required("Field can’t be empty"),
  media_link: yup.string().required("Field can’t be empty"),
  customer: yup.string().required("Field can’t be empty"),
  performer: yup.string().required("Field can’t be empty"),
  base_customer: yup.string().required("Field can’t be empty"),

  platform: yup.number(),
  branch: yup.number().required("Field can’t be empty"),
  gender: yup.number().required("Field can’t be empty"),
  creative_type: yup.number().required("Field can’t be empty"),
  idea_source: yup.number().required("Field can’t be empty"),
  idea_type: yup.number().required("Field can’t be empty"),

  content_type: yup.array(),
  tags: yup.array(),
  technology: yup.array().of(yup.number()).required(),
  social_network: yup.array().of(yup.number()).required(),
  projects: yup.array().min(1).label("Projects").required("Field can’t be empty"),
  resolution: yup.array().of(yup.number()).min(1).label("Resolution").required("Field can’t be empty"),
  languages: yup.array().of(yup.number()).min(1).label("Languages").required("Field can’t be empty"),
  policy_check: yup.number().required("Field can’t be empty"),
});

export default creativeEditSchema;

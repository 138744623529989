import { useEffect } from 'react';
import type { UrlUpdateType } from 'use-query-params';
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import { useFormContext } from 'react-hook-form';
import type { CreativesSettingsState } from '../types';
import type { ColumnKey } from '../types';
import { COLUMNS } from '../constants';

export const CREATIVE_ORDER_PARAM = withDefault(
  createEnumParam<ColumnKey>(Object.keys(COLUMNS) as ColumnKey[]),
  'file_name'
);

type QueryParamsNewValueType<D> = D | ((latestValue: D) => D);
export function useCreativeSortingParam(): [
  ColumnKey | null | undefined,
  (newValue: QueryParamsNewValueType<ColumnKey | null | undefined>, updateType?: UrlUpdateType) => void,
] {
  const { watch } = useFormContext<CreativesSettingsState>();
  const columns = watch('columns');
  const visibleCols = (Object.keys(columns) as ColumnKey[]).filter((k) => columns[k as ColumnKey]);

  const [orderBy, setOrderBy] = useQueryParam('order_by', CREATIVE_ORDER_PARAM);
  useEffect(() => {
    if (!orderBy || !visibleCols.includes(orderBy)) {
      const newOrderBy: ColumnKey =
        (Object.keys(columns) as ColumnKey[]).find((key: ColumnKey) => visibleCols.includes(key)) ?? 'file_name';
      setOrderBy(newOrderBy as ColumnKey);
    }
  }, [orderBy, visibleCols, setOrderBy, columns]);

  return [orderBy, setOrderBy];
}

import { Box, Button, Divider, List, ListItem, styled, Typography } from '@mui/material';
import BgForbidden from 'assets/bg-forbidden.webp';
import LogoAdam from 'assets/logo-adam-grey.svg?react';
import { useUsersList } from '../api';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { Auth } from 'aws-amplify';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { ArrayParam, createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import { useProjects } from '../../projects';
import { isEmpty } from 'lodash-es';

const GradientBackground = styled(Box)`
  padding-left: 40px;
  padding-right: 40px;
  background: url(${BgForbidden}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Forbidden = () => {
  const [projects] = useQueryParam('projects', withDefault(ArrayParam, []));
  const [from] = useQueryParam(
    'from',
    withDefault(createEnumParam<'asset' | 'creative'>(['asset', 'creative']), 'asset')
  );
  const [action] = useQueryParam(
    'action',
    withDefault(createEnumParam<'view' | 'create' | 'update'>(['view', 'create', 'update']), 'view')
  );
  const { data: projectList } = useProjects<{ id: number; name: string }[]>({
    config: {
      select: (data) => data.map(({ name, id }) => ({ id, name })),
    },
  });

  // @ts-expect-error convert p.id to string
  const requestedProjects = isEmpty(projectList) ? [] : projectList.filter((p) => projects.includes(String(p.id)));

  const handleSignOut = async () => {
    await Auth.signOut();
  };

  const { data: users } = useUsersList({
    params: {
      project_id: isEmpty(requestedProjects) ? [] : requestedProjects.map((p) => Number(p.id)),
      access_level: ['admin'],
      offset: 0,
      limit: 50,
    },
  });

  const fromAsset = from === 'asset';

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', flexDirection: 'row', height: '100vh' }}>
      <GradientBackground>
        <Box sx={{ pt: 4, pb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 11.5 }}>
          <LogoAdam />
          <Button
            variant="text"
            size="large"
            component="button"
            onClick={handleSignOut}
            startIcon={<LogoutIcon />}
            sx={(t) => ({ mr: 1, color: t.palette.primary.main, fontWeight: 500, textTransform: 'uppercase' })}
          >
            log out
          </Button>
        </Box>
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            p: 5,
            borderRadius: 1.5,
            height: 480,
            overflowY: 'scroll',
          }}
        >
          {!isEmpty(requestedProjects) && (
            <BreadcrumbLink
              to={fromAsset ? '/assets' : '/creatives'}
              title={`All ${fromAsset ? 'assets' : 'creatives'}`}
            />
          )}
          <Typography
            mb={2}
            variant="h2"
            sx={(t) => ({
              [t.breakpoints.up('md')]: {
                fontSize: t.typography.h1.fontSize,
              },
            })}
            fontWeight={400}
          >
            Request access to {action} {requestedProjects.map((p) => p.name).join(',')} content
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 8 }}>
            <Box sx={{ minWidth: 32 }}>
              <LogoAdam />
            </Box>
            <Typography
              variant="body1"
              sx={(t) => ({
                [t.breakpoints.up('md')]: {
                  fontSize: t.typography.h3.fontSize,
                },
              })}
            >
              Contact any of these project members to get access
            </Typography>
          </Box>
          <List>
            {users.data.map(({ email, id }) => (
              <>
                <ListItem
                  key={id}
                  sx={{ py: 2, width: '100%', display: 'flex', justifyContent: 'space-between', px: 0 }}
                >
                  <Typography variant="body1">{email}</Typography>
                </ListItem>
                <Divider component="li" sx={{ borderColor: 'black' }} />
              </>
            ))}
          </List>
        </Box>
      </GradientBackground>
    </Box>
  );
};

export default Forbidden;

import { Auth } from 'aws-amplify';
import type { AxiosError } from 'axios';
import Axios from 'axios';
import config from 'config';
import handleAPIPermissionError from 'utils/handleAPIPermissionError';

const getAuthorizationHeader = async () => `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

const apiClient = Axios.create({
  baseURL: config.ADMIN_API_URL,
  headers: { 'x-API-key': config.ADMIN_API_KEY },
});

apiClient.interceptors.request.use(async (cfg) => {
  cfg.headers.set('Authorization', await getAuthorizationHeader());
  return cfg;
});

apiClient.interceptors.response.use(
  (cfg) => {
    return cfg;
  },
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      handleAPIPermissionError(error);
    }
    return error;
  }
);

export default apiClient;

import { useFormContext } from 'react-hook-form';
import Input from 'components/common/Input';
import { Box, Chip, List, ListItem, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import RadioGroup from 'components/common/RadioGroup';
import type { AssetFormType } from '../types';
import TagsAccordions from './TagsAccordions';
import { ProjectSelect } from 'features/projects';

const AssetForm = () => {
  const formMethods = useFormContext<AssetFormType>();
  const { watch, setValue } = formMethods;

  const tags = watch('tags');
  const selectedTags = isEmpty(tags) ? [] : Object.keys(tags).filter((k) => tags[k]);

  return (
    <Box sx={{ width: '100%' }}>
      <Input containerSx={{ mb: 5 }} name="name" label="Name" required size="small" />
      <Box sx={{ mb: 5 }}>
        <Input name="mediaUrl" label="Media URL" required />
      </Box>
      {!isEmpty(selectedTags) && (
        <>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Tags
          </Typography>
          <Box
            sx={(t) => ({
              border: '1px solid',
              borderColor: t.palette.grey[100],
              borderRadius: `${Number(t.shape.borderRadius / 1.5)}px`,
              mb: 3,
              p: 1,
              display: 'flex',
              gap: 0.75,
              flexWrap: 'wrap',
            })}
          >
            {selectedTags.map((t) => (
              <Chip
                color="primary"
                onDelete={() => {
                  setValue(`tags.${t}`, false);
                }}
                key={t}
                label={t.split(':')[0]}
                sx={(t) => ({ borderRadius: t.borderRadius.sm, margin: '0 !important' })}
              />
            ))}
          </Box>
        </>
      )}
      <TagsAccordions />
      <Input name="thumbnail" label="Thumbnail" required containerSx={{ mb: 5 }} />
      <Input name="comment" label="Comment" containerSx={{ mb: 5 }} minRows={5} multiline />
      <Input name="aiPrompt" label="AI prompt" containerSx={{ mb: 5 }} minRows={5} multiline />

      <List>
        <ListItem sx={{ px: 0, mb: 1.5 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Project*
          </Typography>
          <ProjectSelect required multiple fullWidth name="projects" size="small" />
        </ListItem>
        <ListItem sx={{ px: 0, mb: 1.5 }}>
          <Typography sx={{ width: 120 }} variant="subtitle1">
            Access*
          </Typography>
          <RadioGroup
            row
            name="restricted"
            options={[
              { value: true, label: 'Restricted' },
              { value: false, label: 'Available to all' },
            ]}
          />
        </ListItem>
        <ListItem sx={{ px: 0, mb: 1.5, alignItems: 'flex-start' }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Customer
          </Typography>
          <Input name="customer" size="small" containerSx={{ width: '100%' }} />
        </ListItem>
        <ListItem sx={{ px: 0, mb: 1.5, alignItems: 'flex-start' }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Performer
          </Typography>
          <Input name="performer" size="small" containerSx={{ width: '100%' }} />
        </ListItem>
      </List>
    </Box>
  );
};

export default AssetForm;

import { isAxiosError } from 'axios';
import PermissionsLackSnackbar from 'components/PermissionsLackSnackbar';
import { enqueueSnackbar } from 'notistack';
import { createElement } from 'react';

const CANCELLED_ERROR = 'ERR_CANCELED';

export default function handleAPIPermissionError(error: unknown) {
  if (isAxiosError(error)) {
    if (error.response?.status === 403) {
      enqueueSnackbar(createElement(PermissionsLackSnackbar), {
        key: '403-error',
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    } else if (error.code !== CANCELLED_ERROR) {
      enqueueSnackbar(`Unexpected error. We can't help with this issue now.`, {
        key: '500-error',
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }
}

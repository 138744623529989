import {
  CreativeContainerStyled,
  CreativeDetailsGalleryStyled,
  CreativeDetailsNavigation,
  CreativeDetailsRowStyled,
  CreativeDetailsStyled,
} from './CreativeDetailsComponents';
import { Box, Button, Chip, Collapse, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import MediaContentView from '../../../components/common/MediaContentView';
import CopyButtons from '../../../components/common/CopyButtons';
import CreativesFilesButton from './CreativesFilesButton';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useModal } from '../../../hooks';
import { useCreative } from '../api/getCreative';
import type { CreativeDetailEntry, CreativeDetailsModelKeys } from '../api/types';
import { CREATIVE_DETAILS_FIELD } from '../constants';

const CreativeDetailsContent = () => {
  const { creativeId } = useParams();
  const { isOpen: isMoreDetails, toggle: toggleMoreDetails } = useModal(false);
  const { data: creativeDetails } = useCreative({ id: `${creativeId}` });

  const ListDetailsItem = ({ field }: { field: CreativeDetailsModelKeys }) => {
    const fieldValue = Array.isArray(creativeDetails[field]) ? (
      (creativeDetails[field] as CreativeDetailEntry[]).map((item) => (
        <Chip
          key={`${item.id}.${item.name}`}
          color="default"
          label={
            <Typography
              key={`${item.id}.${item.name}`}
              component="span"
              sx={{ fontSize: 13, textTransform: 'capitalize' }}
            >
              {item.name}
            </Typography>
          }
        />
      ))
    ) : (
      <Typography component="span">{creativeDetails[field] as string | number | null}</Typography>
    );

    return (
      <ListItem sx={{ px: 0, py: 1, minHeight: 48 }} divider>
        <ListItemText sx={{ flex: 1 }}>
          <Typography component="span">{CREATIVE_DETAILS_FIELD[field]}</Typography>
        </ListItemText>
        <ListItemText sx={{ flex: 2, pl: 2, flexWrap: 'wrap' }}>{fieldValue}</ListItemText>
      </ListItem>
    );
  };

  return (
    <CreativeContainerStyled>
      <CreativeDetailsGalleryStyled>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            maxHeight: '100%',
          }}
        >
          <MediaContentView
            type={creativeDetails.media_type}
            url={creativeDetails.media_link}
            thumbnail={creativeDetails.thumbnail}
          />
        </Box>
      </CreativeDetailsGalleryStyled>

      <CreativeDetailsStyled>
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
          <Box sx={{ position: 'relative', flex: 1, height: 30, mb: 1 }}>
            <Typography
              variant="h1"
              sx={(t) => ({
                '&': {
                  fontSize: t.typography.h3,
                },
              })}
            >
              {creativeDetails.file_name}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative', display: 'flex', gap: 2.5 }}>
            <CopyButtons value={creativeDetails.file_name} />
          </Box>
        </Box>

        <Divider sx={{ p: 2 }} />

        <CreativeDetailsRowStyled>
          <Box>
            <CreativesFilesButton creativeId={creativeDetails.id} title="Download" variant="contained" />
          </Box>
        </CreativeDetailsRowStyled>

        <Divider sx={{ p: 2 }} />

        <CreativeDetailsRowStyled>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Comment
          </Typography>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.grey[600] })}>
            {creativeDetails.comment}
          </Typography>
        </CreativeDetailsRowStyled>

        <Divider sx={{ p: 2 }} />

        <CreativeDetailsRowStyled>
          <List sx={{ mb: 3 }}>
            <ListDetailsItem field="id" />
            <ListDetailsItem field="base_id" />
            <ListDetailsItem field="creative_type" />
            <ListDetailsItem field="social_network" />
            <ListDetailsItem field="platform" />
            <ListDetailsItem field="gender" />
            <ListDetailsItem field="branch" />
            <ListDetailsItem field="model_name" />
            <ListDetailsItem field="model_video_id" />
            <ListDetailsItem field="projects" />
          </List>

          <Box>
            <Button
              onClick={toggleMoreDetails}
              variant="text"
              sx={{ textTransform: 'initial', ml: -1 }}
              endIcon={
                <KeyboardArrowDown
                  sx={{ transform: `rotate(${isMoreDetails ? 180 : 0}deg)`, transition: 'transform .2s' }}
                />
              }
            >
              More details
            </Button>
          </Box>
          <Collapse orientation="vertical" in={isMoreDetails}>
            <List>
              <ListDetailsItem field="idea_source" />
              <ListDetailsItem field="idea_type" />
              <ListDetailsItem field="technology" />
              <ListDetailsItem field="resolution" />
              <ListDetailsItem field="languages" />
              <ListDetailsItem field="policy_check" />
              <ListDetailsItem field="customer" />
              <ListDetailsItem field="base_customer" />
              <ListDetailsItem field="performer" />
              <ListDetailsItem field="original_text" />
              <ListDetailsItem field="original_creative_asset" />
              <ListDetailsItem field="access" />
            </List>
          </Collapse>
        </CreativeDetailsRowStyled>
      </CreativeDetailsStyled>

      <CreativeDetailsNavigation creativeId={creativeDetails.id} />
    </CreativeContainerStyled>
  );
};

export default CreativeDetailsContent;

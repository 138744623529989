import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import useMyAccess from '../../users/hooks/useMyAccess';
import { useCallback, type BaseSyntheticEvent, type FC } from 'react';
import { useModal } from 'hooks';
import { useNavigate } from 'react-router';

interface CreateUpdateAssetActionBlockProps {
  onDiscard: () => void;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  isSubmitting: boolean;
}

const DiscardButton: FC<Pick<CreateUpdateAssetActionBlockProps, 'onDiscard'>> = ({ onDiscard }) => {
  const { isOpen, open, close } = useModal();
  const navigation = useNavigate();

  const handleDiscard = useCallback(() => {
    onDiscard();
    close();
  }, [close]);

  const handleGoBack = useCallback(() => {
    close();
    history.length > 1 ? history.back() : navigation('/assets');
  }, [close]);

  return (
    <>
      <Button
        sx={{ border: 'none', '&:hover': { border: 'none' }, px: 0.66 }}
        size="small"
        color="error"
        variant="outlined"
        onClick={open}>Discard</Button>
      <Dialog
        open={isOpen}
        onClose={close}
        PaperProps={{ sx: { maxWidth: 440, width: '90%', py: 1, } }}
        transitionDuration={{
          appear: 0, enter: 0, exit: 0,
        }}>
        <DialogTitle align="center">Discard unsaved changes?</DialogTitle>
        <DialogActions sx={{ justifyContent: 'center', }}>
          <Button onClick={handleGoBack}>Go back</Button>
          <Button onClick={handleDiscard} variant={'contained'} autoFocus>Discard</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const CreateUpdateAssetActionBlock: FC<CreateUpdateAssetActionBlockProps> = ({ onDiscard, onSubmit, isSubmitting }) => {
  const access = useMyAccess();

  if (!access || !access.assets.includes('create') || !access.assets.includes('update')) {
    return null;
  }

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        gap: 1.25,
        borderRadius: 5,
        position: 'fixed',
        right: 24,
        bottom: 40,
        zIndex: 10,
        backgroundColor: 'white',
        p: 1,
        boxShadow:
          '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06)',
        [t.breakpoints.down('md')]: {
          bottom: 10,
        },
      })}
    >
      <DiscardButton onDiscard={onDiscard} />

      <Button
        onClick={onSubmit}
        variant="contained"
        disabled={isSubmitting}
        sx={(t) => ({
          textTransform: 'uppercase',
          borderRadius: `${t.shape.borderRadius * 2}px`,
        })}
      >
        {isSubmitting ? (
          <CircularProgress size={20} sx={{ color: 'white' }} />
        ) : (
          <Typography fontWeight={500} variant="body2">
            Save changes
          </Typography>
        )}
      </Button>
    </Box>
  );
};

export default CreateUpdateAssetActionBlock;

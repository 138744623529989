import { Box, Button, Dialog, DialogActions, CircularProgress, DialogContent, DialogTitle, TextField, Typography, Skeleton } from "@mui/material";
import { SearchButtonStyled } from "./SearchComponents";
import { BookmarkAddOutlined, ErrorOutline } from "@mui/icons-material";
import { useModal } from "hooks";
import type { FC } from "react";
import { Suspense, useCallback, useEffect } from "react";
import type { ControllerRenderProps, FieldErrors, UseFormSetValue } from "react-hook-form";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreatePreset, useSearchTagsState, savePresetSchema, usePresets } from "features/search";
import TagsAutocomplete from "./TagsAutocomplete";
import { useSearchContext } from "../context";

interface FormValues {
  presetName: string;
  tags: number[];
  presetQueryString?: string;
}

interface SavePresetProps {
  refParent: {
    current: HTMLDivElement | null;
  },
}

interface PresetNameTextFieldProps {
  errors: FieldErrors<FormValues>;
  field: ControllerRenderProps<FormValues, "presetName">;
  isPending: boolean;
  actualPresetName: string;
  setValue: UseFormSetValue<FormValues>;
}

const PresetNameTextField: FC<PresetNameTextFieldProps> = ({ errors, field, isPending, actualPresetName, setValue }) => {
  const { searchType } = useSearchContext();
  const { data } = usePresets(searchType);

  useEffect(() => {
    setValue('presetName', `My preset #${data.length + 1}`);
  }, [setValue, data]);

  return (
    <TextField
      size="medium"
      label="Preset name *"
      fullWidth
      variant="outlined"
      error={!!errors.presetName?.type}
      helperText={
        errors.presetName?.type === "required" && <Typography fontSize={14} marginTop={-2} marginBottom={2}>{errors.presetName.message}</Typography>
      }
      InputProps={{
        sx: { mb: 2, },
        endAdornment: errors.presetName?.type ? (
          <ErrorOutline color="error" sx={{ width: 22 }} />
        ) : null
      }}
      inputProps={{ style: { height: 40, fontSize: 14, fontWeight: 400, padding: '0 14px' }, maxLength: 120 }}
      InputLabelProps={{
        sx: { '&.MuiInputLabel-shrink': { top: 0, fontSize: 16, }, fontSize: 14, top: -5, }
      }}
      {...field}
      disabled={isPending}
      value={actualPresetName}
    />)
}

const SavePreset: FC<SavePresetProps> = ({ refParent }) => {
  const { searchType, queryFieldValue } = useSearchContext();
  const { selectedTags } = useSearchTagsState();
  const { isOpen, open, close } = useModal();
  const formMethods = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(savePresetSchema),
    defaultValues: {
      presetName: "",
      tags: selectedTags,
      presetQueryString: queryFieldValue,
    },
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, },
  } = formMethods;

  const actualPresetName = useWatch({ control, name: 'presetName' });
  const actualTags = useWatch({ control, name: 'tags' });
  const actualQueryString = useWatch({ control, name: 'presetQueryString' });

  const handleResetAll = useCallback(() => {
    setValue('presetName', '');
    setValue('presetQueryString', '');
    setValue('tags', []);
  }, [setValue])

  const createPreset = useCreatePreset({
    config: {
      onSuccess: () => {
        refParent.current?.classList.remove('hidden');
        handleResetAll();
        close();
      },
    }
  });

  const handleAddPreset = useCallback(() => {
    createPreset.mutate({
      type: searchType,

      name: actualPresetName,
      tags: actualTags,
      q: actualQueryString,
    });
  }, [createPreset, searchType, actualPresetName, actualTags, actualQueryString]);

  const handleOpenPreset = useCallback(() => {
    refParent.current?.classList.add('hidden');

    setValue('tags', selectedTags);
    setValue('presetQueryString', queryFieldValue);
    open();
  }, [open, setValue, queryFieldValue, selectedTags, refParent])

  const handleClosePreset = useCallback(() => {
    if (createPreset.isPending) return;

    refParent.current?.classList.remove('hidden');

    handleResetAll();
    close();
  }, [close, handleResetAll, refParent, createPreset.isPending]);

  const handleTagsChange = useCallback((value: number[]) => {
    setValue('tags', value);
  }, [setValue]);

  const onAddPreset = useCallback(() => {
    handleSubmit(handleAddPreset)().catch(console.error);
  }, [handleSubmit, handleAddPreset]);

  return (
    <Box>
      <SearchButtonStyled
        id="presets-dropdown-button"
        active={isOpen}
        onClick={handleOpenPreset}
        startIcon={<BookmarkAddOutlined />}
        variant="text"
        sx={{ whiteSpace: 'nowrap', }}>Save Preset</SearchButtonStyled>
      <Dialog
        disableRestoreFocus
        open={isOpen}
        onClose={handleClosePreset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { maxWidth: 440, width: '90%', py: 1, } }}
        transitionDuration={{
          appear: 0, enter: 0, exit: 0,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          align={'center'}
          sx={t => ({ fontWeight: 500, fontSize: t.typography.h6.fontSize, })}>New preset</DialogTitle>
        <DialogContent sx={{ flexDirection: 'column', }}>
          <Box sx={{ pt: 1, }}>
            <Controller control={control} name="presetName" render={({ field }) => (
              <Suspense fallback={<Skeleton width={'100%'} height={'40px'} sx={{ borderRadius: '8px', transform: 'scale(1)', mb: 2, }} />}>
                <PresetNameTextField
                  isPending={createPreset.isPending}
                  errors={errors}
                  field={field}
                  actualPresetName={actualPresetName}
                  setValue={setValue}
                />
              </Suspense>
            )} />
          </Box>
          <Box sx={{ mb: 2, }}>
            <Controller control={control} name="tags" render={({ field }) => (
              <Suspense fallback={<>Loading...</>}>
                <TagsAutocomplete
                  size="small"
                  value={actualTags}
                  onChange={handleTagsChange}
                  disabled={createPreset.isPending}
                  textFieldProps={{
                    name: field.name,
                    disabled: field.disabled,
                    ref: field.ref,
                    onBlur: field.onBlur,
                    label: "Tags *",
                    error: !!errors.tags?.type,
                    helperText: errors.tags?.type === "min" && <Typography fontSize={14}>{errors.tags.message}</Typography>,
                  }} />
              </Suspense>
            )} />
          </Box>
          <Box>
            <Controller control={control} name="presetQueryString" render={({ field }) => (
              <TextField
                size="medium"
                label="Search query"
                fullWidth
                variant="outlined"
                InputProps={{
                  sx: { mb: 2, },
                }}
                inputProps={{ style: { height: 40, fontSize: 14, fontWeight: 400, padding: '0 14px' }, maxLength: 120 }}
                InputLabelProps={{
                  sx: { '&.MuiInputLabel-shrink': { top: 0, fontSize: 16, }, fontSize: 14, top: -5, }
                }}
                {...field}
                disabled={createPreset.isPending}
              />
            )} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', }}>
          <Button onClick={handleClosePreset} disabled={createPreset.isPending}>Cancel</Button>
          <Button
            onClick={onAddPreset}
            autoFocus
            variant="contained"
            disabled={createPreset.isPending}
            startIcon={createPreset.isPending ? <CircularProgress size={16} /> : null}>Add preset</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
};

export default SavePreset;

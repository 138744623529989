/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SxProps } from "@mui/material";
import { Box, Typography } from "@mui/material";
import type { FocusEvent } from "react";
import { type FC, useCallback } from "react";
import { useParams } from "react-router";
import MediaContentView from "components/common/MediaContentView";
import {
  CreativeContainerStyled,
  CreativeDetailsGalleryStyled,
  CreativeDetailsStyled,
  CreativeEditNavigation,
  CreativeEditRowStyled,
} from "./CreativeDetailsComponents";
import { useCreative } from "../api/getCreative";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import creativeEditSchema from "../validation/creativeEdit.schema";
import Input from "components/common/Input";
import type { CreativeDetailsModel, CreativeDetailsModelKeys, CreativeGroupId } from "../api/types";
import Select from "components/common/Select";
import { useCreativeGroupTags } from "../api/getCreativeGroupTags";
import { ACCESS_OPTIONS } from "../constants";
import { getDetailIdValue } from "../utils";
import RadioGroup from "components/common/RadioGroup";
import { useUpdateCreative } from "../api/updateCreative";
import useEnqueueSnackbar from "hooks/useEnqueueSnackbar";
import { isNumber } from "lodash-es";

interface CreativeEditInputProps {
  direction?: 'row' | 'column';
  name: CreativeDetailsModelKeys;
  label: string;
  multiline?: boolean;
  required?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}

const CreativeEditInput: FC<CreativeEditInputProps> =
  ({ direction = 'column', name, label, multiline, required, onBlur, sx, }) => (
    <CreativeEditRowStyled sx={{ mb: 1, ...sx, }}>
      <Input
        name={name}
        label={label}
        required={required}
        multiline={multiline}
        onBlur={onBlur}
        titleSx={{ fontWeight: 400, fontSize: 16, flex: 1, whiteSpace: 'nowrap', maxWidth: 120, }}
        inputStyle={direction === 'row' ? { flex: 2, height: 24, padding: '8px 12px', } : {}}
        containerSx={direction === 'row' ? { mb: 1, gap: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', } : { mb: 1, }}
      />
    </CreativeEditRowStyled>
  )

const CreativeEditSelect: FC<{
  name: CreativeDetailsModelKeys;
  label: string;
  multiple?: boolean;
  required?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}> =
  ({ name, label, multiple, required, onBlur, sx, }) => {
    const { data: creativeGroupTags } = useCreativeGroupTags({
      groupId: name.replace('_', '-') as CreativeGroupId,
      config: {
        select: (data) => data.map((item) => ({ value: item.id, label: item.name })),
      }
    });

    return (
      <CreativeEditRowStyled sx={{ mb: 1, ...sx, }}>
        <Select
          name={name}
          label={label}
          required={required}
          multiple={multiple}
          options={creativeGroupTags}
          onBlur={onBlur}
          titleSx={{ fontWeight: 400, fontSize: 16, flex: 1, whiteSpace: 'nowrap', maxWidth: 120, }}
          containerSx={{ mb: 1, gap: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
        />
      </CreativeEditRowStyled>
    )
  }

const CreativeDetails: FC = () => {
  const { creativeId } = useParams();
  const { data: creativeDetails } = useCreative({ id: `${creativeId}` });
  const {
    file_name,
    thumbnail,
    media_link,
    download_files_link,
    comment,
    base_id,
    model_name,
    creative_type,
    model_video_id,
    customer,
    base_customer,
    performer,
    original_text,
    original_creative_asset,
    social_network,
    platform,
    gender,
    branch,
    idea_source,
    idea_type,
    projects,
    access,
    technology,
    resolution,
    languages,
    policy_check,
  } = creativeDetails;

  const formMethods = useForm({
    resolver: yupResolver(creativeEditSchema),
    defaultValues: {
      creative_type: getDetailIdValue(creative_type),
      platform: getDetailIdValue(platform),
      gender: getDetailIdValue(gender),
      branch: getDetailIdValue(branch),
      idea_source: getDetailIdValue(idea_source),
      policy_check: getDetailIdValue(policy_check),
      idea_type: getDetailIdValue(idea_type),
      social_network: social_network.map(getDetailIdValue),
      projects: projects.map(getDetailIdValue),
      technology: technology.map(getDetailIdValue),
      resolution: resolution.map(getDetailIdValue),
      languages: languages.map(getDetailIdValue),
      access,
      file_name,
      thumbnail,
      media_link,
      download_files_link,
      comment,
      base_id,
      model_name,
      model_video_id,
      customer,
      base_customer,
      performer,
      original_text,
      original_creative_asset,
    }
  });
  const { handleSubmit, reset, trigger, watch, getValues } = formMethods;
  const { onShowAlert } = useEnqueueSnackbar();

  const mediaLinkPath = watch('media_link');
  const thumbnailPath = watch('thumbnail');

  const updateCreative = useUpdateCreative({
    config: {
      onSuccess: () => {
        onShowAlert("Changes saved");
      },
      onError: () => {
        onShowAlert("Error");
      },
    }
  });

  const onSubmit = useCallback(() => {
    const {
      access,
      creative_type,
      platform,
      gender,
      branch,
      idea_source,
      policy_check,
      idea_type,
      social_network,
      projects,
      file_name,
      thumbnail,
      media_link,
      download_files_link,
      comment,
      base_id,
      model_video_id,
      model_name,
      customer,
      base_customer,
      performer,
      original_text,
      original_creative_asset,
      technology,
      resolution,
      languages
    } = getValues();

    updateCreative.mutate({
      id: creativeId!,
      tags: [
        creative_type,
        platform,
        branch,
        idea_source,
        idea_type,
        gender,
        policy_check,
        ...social_network,
        ...technology,
        ...resolution,
        ...languages,
      ].filter(i => isNumber(i)) as number[],
      access: typeof access === 'string' ? access === 'true' : access,
      projects,
      file_name,
      thumbnail,
      model_name,
      media_link,
      download_files_link,
      comment,
      base_id,
      model_video_id,
      customer,
      base_customer,
      performer,
      original_text,
      original_creative_asset,
    })
  }, [getValues, updateCreative.mutate]);

  const handleValidationField = useCallback((e: FocusEvent<HTMLInputElement>) => {
    trigger(e.target.name as keyof CreativeDetailsModel).catch(console.error);
  }, [trigger]);

  return (
    <CreativeContainerStyled>
      <CreativeDetailsGalleryStyled>
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, height: 420, }}>
          <MediaContentView type={creativeDetails.media_type} url={mediaLinkPath} thumbnail={thumbnailPath} />
        </Box>
      </CreativeDetailsGalleryStyled>

      <CreativeDetailsStyled>
        <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, height: 30, mb: 3, }}>
          <Typography
            variant="h1"
            sx={t => ({
              '&': {
                position: 'absolute',
                display: 'block',
                width: '100%',
                fontSize: t.typography.h3,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }
            })}>Edit creative</Typography>
        </Box>

        <FormProvider {...formMethods}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4, }}>
            <CreativeEditInput name="file_name" label={"File name"} required onBlur={handleValidationField} />
            <CreativeEditInput name="thumbnail" label={"Thumbnail"} />
            <CreativeEditInput name="media_link" label={"Media Link"} required onBlur={handleValidationField} />
            <CreativeEditInput name="download_files_link" label={"Download files link"} />
            <CreativeEditInput name="comment" label={"Comment"} multiline />
          </Box>
          <CreativeEditInput name="base_id" label={"Base ID"} direction="row" required onBlur={handleValidationField} />
          <CreativeEditSelect name="creative_type" label={"Creative type"} required onBlur={handleValidationField} />
          <CreativeEditSelect name="social_network" label={"Social network"} multiple />
          <CreativeEditSelect name="platform" label={"Platform"} />
          <CreativeEditSelect name="gender" label={"Gender"} required onBlur={handleValidationField} />
          <CreativeEditSelect name="branch" label={"Branch"} required onBlur={handleValidationField} />
          <CreativeEditInput name="model_name" label={"Model name"} direction="row" />
          <CreativeEditInput name="model_video_id" label={"Model video id"} direction="row" />
          <CreativeEditSelect name="projects" label={"Project name"} required onBlur={handleValidationField} multiple />
          <CreativeEditSelect name="idea_source" label={"Idea source"} required onBlur={handleValidationField} />
          <CreativeEditSelect name="idea_type" label={"Idea type"} required onBlur={handleValidationField} />
          <CreativeEditSelect name="technology" label={"Technology"} multiple />
          <CreativeEditSelect name="resolution" label={"Resolution"} multiple required onBlur={handleValidationField} />
          <CreativeEditSelect name="languages" label={"Languages"} multiple required onBlur={handleValidationField} />
          <CreativeEditSelect name="policy_check" label={"Policy check"} required onBlur={handleValidationField} />
          <CreativeEditInput name="customer" label={"Customer"} direction="row" required onBlur={handleValidationField} />
          <CreativeEditInput name="base_customer" label={"Base customer"} direction="row" required onBlur={handleValidationField} />
          <CreativeEditInput name="performer" label={"Performer"} direction="row" required onBlur={handleValidationField} />
          <CreativeEditInput name="original_text" label={"Original text"} direction="row" />
          <CreativeEditInput name="original_creative_asset" label={"Original Creative"} direction="row" />
          <Box>
            <RadioGroup name="access" label="Access*"
              options={ACCESS_OPTIONS}
              labelProps={{ sx: t => ({ fontSize: t.typography.body1.fontSize, fontWeight: 400, color: t.palette.primary.main, flex: 1, whiteSpace: 'nowrap', maxWidth: 120, }) }}
              controlProps={{ sx: { display: 'flex', flex: 2, gap: 3, flexDirection: 'row', justifyContent: 'start', alignItems: 'center', } }}
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', mb: 1, '& .MuiFormControlLabel-root:last-child': { mr: 0, } }}
            />
          </Box>
        </FormProvider>

      </CreativeDetailsStyled>

      <CreativeEditNavigation isPending={updateCreative.isPending} onSaveChanges={handleSubmit(onSubmit)} onDiscard={reset} />
    </CreativeContainerStyled >
  )
}

export default CreativeDetails;

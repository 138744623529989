import { useCallback, useEffect, useMemo } from 'react';
import { Box, LinearProgress } from '@mui/material';
import type { SortingOptionValue } from '../api';
import { useAssetsList } from '../api';
import { useAssets } from '../context';
import AssetItem from './AssetItem';
import { AssetListContainer, AssetNoResults } from './AssetsListComponents';
import AssetPreviewPopup from './AssetPreviewPopup';
import useListParams from 'hooks/useListParams';
import { isEmpty } from 'lodash-es';

const AssetsList = () => {
  const { listType, handleSetCountAssets, countAssets } = useAssets();
  const { desc, orderBy, favourite, tags, q, previewId } = useListParams();

  const { hasNextPage, isFetchingNextPage, fetchNextPage, data } = useAssetsList({
    params: { order_by: orderBy as SortingOptionValue, desc, favourite, tags: tags as number[], q },
  });

  const { count: countAssetsFetched, data: assetsList } = { ...data };

  const handleScroll = useCallback(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    if (window.innerHeight + window.scrollY + 100 >= document.documentElement.scrollHeight) {
      fetchNextPage().catch(console.error);
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (countAssets !== countAssetsFetched) {
      handleSetCountAssets(countAssetsFetched);
    }
  }, [countAssets, countAssetsFetched, handleSetCountAssets]);

  const AssetsListMemo = useMemo(
    () =>
      !isEmpty(assetsList) ? (
        assetsList.map((asset, i) => <AssetItem key={`${i}.${asset.id}.${listType}`} asset={asset} />)
      ) : (
        <AssetNoResults />
      ),
    [assetsList, listType]
  );

  return (
    <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, py: 2, flexDirection: 'column' }}>
      <AssetListContainer>
        {AssetsListMemo}
        {previewId && (
          <AssetPreviewPopup
            assetsList={assetsList}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
          />
        )}
      </AssetListContainer>
      {isFetchingNextPage &&
        <LinearProgress
          sx={t => ({
            position: 'fixed',
            bottom: '1rem',
            left: 0,
            right: 0,
            height: 4,
            zIndex: 10,
            [t.breakpoints.up('sm')]: {
              ml: 'calc(80px + 1.5rem)',
            },
            [t.breakpoints.down('sm')]: {
              ml: '1.5rem',
            },
            mr: '1.5rem',
          })} />}
    </Box>
  );
};

export default AssetsList;

import { Suspense, useEffect } from 'react';
import { Titled } from 'react-titled';
import { Box, Container, Grid, Typography } from '@mui/material';
import CircularSpinner from '../../components/common/CircularSpinner';
import { Layout } from '../../components/layout';
import { AssetForm, AssetsContextProvider, CreateUpdateAssetActionBlock, useUpdateAsset } from 'features/assets';
import { FormProvider, useForm } from 'react-hook-form';
import type { AssetFormType } from 'features/assets';
import { yupResolver } from '@hookform/resolvers/yup';
import { assetSchema } from 'features/assets';
import { useAsset } from 'features/assets';
import { useNavigate, useParams } from 'react-router';
import { isEmpty } from 'lodash-es';
import { getAssetTypeFromUrl } from 'features/assets';
import extractSelectedTagsIds from '../../features/assets/utils/extractSelectedTagsIds';
import MediaContentView from 'components/common/MediaContentView';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { Search, SearchContextProvider } from 'features/search';
import { useMyAccess } from '../../features/users';

const UpdateAsset = () => {
  const { assetId } = useParams();
  const { data: asset } = useAsset({ id: Number(assetId) });
  const navigate = useNavigate();
  const access = useMyAccess();

  useEffect(() => {
    if (access && !access.assets.includes('update')) {
      navigate({
        pathname: '/forbidden',
        search: new URLSearchParams({
          from: 'asset',
          action: 'update',
        }).toString(),
      });
    }
  }, [access, navigate]);

  const { mutateAsync, isPending } = useUpdateAsset({
    config: {
      onSuccess: () => {
        navigate('/assets');
      },
    },
  });

  const formMethods = useForm<AssetFormType>({
    resolver: yupResolver(assetSchema),
    defaultValues: {
      name: asset.name,
      mediaUrl: asset.media_url,
      thumbnail: asset.thumbnail,
      tags: !isEmpty(asset.tags)
        ? asset.tags.reduce<Record<string, boolean>>((acc, currentTag) => {
            const key = `${currentTag.name}:${currentTag.id}`;
            acc[key] = true;
            return acc;
          }, {})
        : {},
      comment: asset.comment ?? '',
      aiPrompt: asset.ai_prompt ?? '',
      projects: asset.projects.map((p) => p.id),
      restricted: asset.restricted,
      customer: asset.customer ?? '',
      performer: asset.performer ?? '',
    },
  });

  const { reset, handleSubmit, watch } = formMethods;

  const [thumbnail, url] = [watch('thumbnail'), watch('mediaUrl')];

  const onSubmit = async ({
    name,
    mediaUrl,
    aiPrompt,
    customer,
    tags,
    comment,
    performer,
    thumbnail,
    restricted,
    projects,
  }: AssetFormType) => {
    const selectedTagIds = extractSelectedTagsIds(tags);

    await mutateAsync({
      id: Number(assetId),
      name,
      media_url: mediaUrl,
      ai_prompt: aiPrompt,
      customer,
      comment,
      performer,
      thumbnail,
      restricted,
      projects,
      tags: selectedTagIds,
    });
  };

  const discardChanges = () => {
    reset();
  };

  return (
    <FormProvider {...formMethods}>
      <AssetsContextProvider>
        <SearchContextProvider searchType="assets">
          <Layout childrenContainerClasses={{ pb: 12 }}>
            <Titled title={(title) => `Update asset | ${title}`} />
            <Box
              sx={(t) => ({
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                [t.breakpoints.down('sm')]: {
                  pt: 6,
                },
                [t.breakpoints.up('sm')]: {
                  pt: 1,
                },
                mb: 6,
                height: 48,
              })}
            >
              <BreadcrumbLink to="/assets" title="Back" />
              <Search />
            </Box>
            <Container
              sx={(t) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                p: 0,
                [t.breakpoints.up('md')]: {
                  flexDirection: 'row',
                },
              })}
              maxWidth={false}
            >
              <Suspense fallback={<CircularSpinner containerSx={{ height: '100vh' }} />}>
                <Grid container item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
                  <Box
                    sx={(t) => ({
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      maxHeight: 420,
                      [t.breakpoints.up('xl')]: {
                        maxHeight: 700,
                      },
                    })}
                  >
                    <MediaContentView thumbnail={thumbnail} type={getAssetTypeFromUrl(url)} url={url} />
                  </Box>
                </Grid>
                <Grid container item xs={12} md={6} display="flex" flexDirection="column">
                  <Typography variant="h3" sx={{ mb: 2.5 }}>
                    Edit asset
                  </Typography>
                  <AssetForm />
                  <CreateUpdateAssetActionBlock
                    onSubmit={handleSubmit(onSubmit)}
                    onDiscard={discardChanges}
                    isSubmitting={isPending}
                  />
                </Grid>
              </Suspense>
            </Container>
          </Layout>
        </SearchContextProvider>
      </AssetsContextProvider>
    </FormProvider>
  );
};

export default UpdateAsset;

import type { FC } from 'react';
import { Titled } from 'react-titled';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Box } from '@mui/material';
import { Layout } from 'components/layout';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { AssetDetails, AssetsContextProvider } from 'features/assets';
import { Search, SearchContextProvider } from 'features/search';
import useListParams from 'hooks/useListParams';

const AssetDetailsPage: FC = () => {
  const { tags } = useListParams();
  const formMethods = useForm({
    defaultValues: {
      searchTags: tags ? tags.reduce((collection, tagId) => ({ ...collection, [`${tagId}`]: true }), {}) : {},
    },
  });

  return (
    <FormProvider {...formMethods}>
      <AssetsContextProvider>
        <SearchContextProvider searchType="assets">
          <Layout childrenContainerClasses={{ paddingTop: '24px', paddingBottom: '40px' }}>
            <Titled title={(title) => `Asset details | ${title}`} />
            <Box
              sx={(t) => ({
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                [t.breakpoints.down('sm')]: {
                  pt: 6,
                },
                [t.breakpoints.up('sm')]: {
                  pt: 1,
                },
                mb: 6,
                height: 48,
              })}
            >
              <BreadcrumbLink to="/assets" title="Assets" />
              <Search />
            </Box>
            <Container maxWidth={false} sx={{ '&': { p: 0 } }}>
              <AssetDetails />
            </Container>
          </Layout>
        </SearchContextProvider>
      </AssetsContextProvider>
    </FormProvider>
  );
};

export default AssetDetailsPage;

import { isEmpty } from "lodash-es";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

const useSearchTagsState = () => {
  const { watch, setValue } = useFormContext();
  const stateSearchTags = watch('searchTags') as Record<string, boolean>;
  const selectedTags =
    !isEmpty(stateSearchTags)
      ? Object.keys(stateSearchTags).map(Number).filter((k) => stateSearchTags[k])
      : [];

  const predefineStateValues = useCallback((data: number[], value: boolean) => (
    data.reduce((collection: Record<string, boolean>, tagId: number) => ({ ...collection, [`${tagId}`]: value, }), {})
  ), []);

  const onResetStateTags = useCallback(() => {
    setValue('searchTags', []);
  }, [setValue]);

  const onSelectTags = useCallback((values: number[] | undefined) => {
    if (values)
      setValue('searchTags', predefineStateValues(values, true))
  }, [predefineStateValues, setValue]);

  const onSelectTag = useCallback((id: number, value: boolean) => {
    setValue(`searchTags.${id}`, value);
  }, [setValue]);

  return {
    selectedTags,
    onSelectTag,
    onSelectTags,
    onResetStateTags
  }
};

export default useSearchTagsState;

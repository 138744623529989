import { useTheme } from '@mui/material';
import { type FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface LocationState {
  search?: string;
}

const BreadcrumbLink: FC<{ to: string; title: string }> = ({ to, title }) => {
  const t = useTheme();
  const location = useLocation();
  const searchState = location.state as LocationState | null;

  return (
    <Link
      to={{
        pathname: to,
        search: searchState?.search ?? '',
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: t.palette.text.primary,
        fontSize: t.typography.body1.fontSize,
        lineHeight: '2rem',
      }}
    >
      <KeyboardArrowLeftIcon sx={{ mr: 1, ml: -0.5 }} /> {title}
    </Link>
  );
};

export default BreadcrumbLink;

import { Box, Typography } from "@mui/material";
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { TagGroups, AddTagGroup } from 'features/tags';
import { type FC } from "react";
import { Titled } from "react-titled";

const TagsPage: FC = () => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
      <Titled title={(title) => `ADAM | ${title}`} />
      <BreadcrumbLink to="/assets" title="Assets" />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, }}>
        <Typography variant="h1" sx={{ lineHeight: 1, }}>Tag groups</Typography>
        <AddTagGroup />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'stretch', height: '100%', }}>
        <TagGroups />
      </Box>
    </Box>
  );
};

export default TagsPage;
